import {getFormattedMoney} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useCommonDateInformation} from '../../../../commons/hooks/dates'
import {Divider} from '../styled-components/divider'
import {DottedDivider} from '../styled-components/dotted-divider'
import {EventText} from '../styled-components/event-text'
import {DownloadTicketsButton} from '../actions/download-tickets-button'
import s from './order-details.scss'
import {OrderWithSeating} from './order-with-seating'
import {OrderSummary} from './order-summary'
import {OrderDetailsProps} from '.'

const TableHeader = ({t}) => (
  <div className={classNames(s.row)}>
    <div className={s.colTicket}>
      <EventText>{t('order.header.ticket-type')}</EventText>
    </div>
    <div className={s.colPrice}>
      <EventText>{t('order.header.price')}</EventText>
    </div>
    <div className={s.colQuantity}>
      <EventText>{t('order.header.quantity')}</EventText>
    </div>
    <div className={s.colTotal}>
      <EventText>{t('order.header.total')}</EventText>
    </div>
  </div>
)

const Item = ({item}) => (
  <div className={s.row}>
    <div className={s.colTicket}>
      <EventText>{item.name}</EventText>
    </div>
    <div className={s.colPrice}>
      <EventText>{getFormattedMoney(item.price)}</EventText>
    </div>
    <div className={s.colQuantity}>
      <EventText>{item.quantity}</EventText>
    </div>
    <div className={s.colTotal}>
      <EventText>{getFormattedMoney(item.total)}</EventText>
    </div>
  </div>
)

export const OrderDetails = ({order, t, event, isOwner}: OrderDetailsProps) => {
  const {orderNumber, invoice} = order
  const orderDate = useCommonDateInformation(orderNumber)
  const ticketsWithSeats = order.tickets.some(ticket => ticket.ticketDetails)
  if (ticketsWithSeats) {
    return <OrderWithSeating order={order} event={event} />
  }

  return (
    <div className={s.root} data-hook={DH.ORDER_DETAILS}>
      <DottedDivider />
      <div className={s.content}>
        <div className={s.header}>
          <EventText>{t('order.number', {orderNumber})}</EventText>
          <EventText>{t('order.date', {date: orderDate})}</EventText>
        </div>
        {isOwner ? (
          <div className={s.downloadOrderTicketsContainer}>
            <DownloadTicketsButton event={event} order={order} />
          </div>
        ) : null}
        <Divider />
        <TableHeader t={t} />
        <Divider />
        {invoice.items.map(item => (
          <Item key={item.id} item={item} />
        ))}
        <Divider />
        <OrderSummary invoice={invoice} />
      </div>
    </div>
  )
}
