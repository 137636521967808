import classNames from 'classnames'
import React from 'react'
import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {TFunction} from '@wix/yoshi-flow-editor'
import {getFormattedMoney} from '@wix/wix-events-commons-statics'
import {useCommonDateInformation} from '../../../../../commons/hooks/dates'
import {Divider} from '../../styled-components/divider'
import {DottedDivider} from '../../styled-components/dotted-divider'
import {EventText} from '../../styled-components/event-text'
import {getTicketsWithoutTax, isAreaTicket, isRowTicket, isTableTicket} from '../../../selectors/tickets'
import {OrderSummary} from '../order-summary'
import {DownloadTicketsButton} from '../../actions/download-tickets-button'
import s from './order-with-seating.scss'
import {OrderWithSeatingProps} from '.'

const TableHeader = ({t}) => (
  <div className={classNames(s.row)}>
    <div className={s.colTicket}>
      <EventText>{t('order.header.ticket-type')}</EventText>
    </div>
    <div className={s.colSeatInfo}>
      <EventText>{t('order.header.seatInfo')}</EventText>
    </div>
    <div className={s.colTotal}>
      <EventText>{t('order.header.price')}</EventText>
    </div>
  </div>
)

interface ItemProps {
  ticket: wix.events.ticketing.Ticket
  t: TFunction
}

interface SeatInfoCol {
  header: string
  text: string
}

const Item = ({ticket, t}: ItemProps) => {
  const hasSection = ticket.ticketDetails?.sectorName
  const seatInfoCols: SeatInfoCol[] = []
  if (hasSection) {
    seatInfoCols.push({header: t('order.seating.section'), text: ticket.ticketDetails.sectorName})
  }
  if (isRowTicket(ticket)) {
    seatInfoCols.push({header: t('order.seating.row'), text: ticket.ticketDetails.rowNumber})
    seatInfoCols.push({header: t('order.seating.seat'), text: ticket.ticketDetails.seatNumber})
  }
  if (isAreaTicket(ticket)) {
    seatInfoCols.push({header: t('order.seating.area'), text: ticket.ticketDetails.areaName})
  }
  if (isTableTicket(ticket)) {
    seatInfoCols.push({header: t('order.seating.table'), text: ticket.ticketDetails.tableName})
    seatInfoCols.push({header: t('order.seating.seat'), text: ticket.ticketDetails.seatNumber})
  }

  return (
    <div className={s.row}>
      <div className={s.colTicket}>
        <EventText>{ticket.name}</EventText>
      </div>
      {isRowTicket(ticket) || isTableTicket(ticket) ? (
        <div className={s.colSeatInfo}>
          <div className={s.firstSeatingCol}>
            <EventText extraClass={s.ticketDetailsHeader}>{seatInfoCols[0].header}</EventText>
            <EventText>{seatInfoCols[0].text}</EventText>
          </div>
          <div className={s.secondSeatingCol}>
            {seatInfoCols.slice(1).map(col => (
              <div className={s.colSeating}>
                <EventText extraClass={s.ticketDetailsHeader}>{col.header}</EventText>
                <EventText>{col.text}</EventText>
              </div>
            ))}
          </div>
        </div>
      ) : hasSection ? (
        <div className={s.colSeatInfo}>
          <div className={s.firstSeatingCol}>
            <EventText extraClass={s.ticketDetailsHeader}>{seatInfoCols[0].header}</EventText>
            <EventText>{seatInfoCols[0].text}</EventText>
          </div>
          <div className={s.secondSeatingCol}>
            {seatInfoCols.slice(1).map(col => (
              <div className={s.colSeating}>
                <EventText extraClass={s.ticketDetailsHeader}>{col.header}</EventText>
                <EventText>{col.text}</EventText>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={s.colSeatInfo}>
          <div className={s.colSeating}>
            <EventText extraClass={s.ticketDetailsHeader}>{t('order.seating.area')}</EventText>
            <EventText>{ticket.ticketDetails.areaName}</EventText>
          </div>
        </div>
      )}
      <div className={s.colTotal}>
        <EventText>{getFormattedMoney(ticket.price)}</EventText>
      </div>
    </div>
  )
}
export const OrderWithSeating = ({order, t, event, isOwner}: OrderWithSeatingProps) => {
  const {orderNumber, invoice} = order
  const orderDate = useCommonDateInformation(orderNumber)
  const tickets = getTicketsWithoutTax(order)

  return (
    <div className={s.root} data-hook={DH.ORDER_DETAILS}>
      <DottedDivider />
      <div className={s.content}>
        <div className={s.header}>
          <EventText>{t('order.number', {orderNumber})}</EventText>
          <EventText>{t('order.date', {date: orderDate})}</EventText>
        </div>
        {isOwner ? (
          <div className={s.downloadOrderTicketsContainer}>
            <DownloadTicketsButton event={event} order={order} />
          </div>
        ) : null}
        <Divider />
        <TableHeader t={t} />
        <Divider />
        {tickets.map(item => (
          <Item key={item.ticketNumber} ticket={item} t={t} />
        ))}
        <Divider />
        <OrderSummary invoice={invoice} />
      </div>
    </div>
  )
}
