export const isAreaTicket = (ticket: wix.events.ticketing.Ticket) => Boolean(ticket.ticketDetails.areaName)

export const isRowTicket = (ticket: wix.events.ticketing.Ticket) =>
  ticket.ticketDetails.rowNumber !== undefined && ticket.ticketDetails.rowNumber !== null

export const isTableTicket = (ticket: wix.events.ticketing.Ticket) => Boolean(ticket.ticketDetails.tableName)

export const getTicketsWithoutTax = (order: wix.events.ticketing.Order) =>
  order.tickets.map(ticket => ({...ticket, price: getTicketPriceWithoutTax(order, ticket.ticketDefinitionId)}))

export const getTicketPriceWithoutTax = (order: wix.events.ticketing.Order, ticketDefinitionId: string) =>
  order.invoice?.items?.find(item => item.id === ticketDefinitionId)?.price
